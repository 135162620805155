import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { DtoReqChangePassword } from "../Domain/DtoReqChangePassword";
import { Buffer } from 'buffer'

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {

    public async changePassword(params: DtoReqChangePassword): Promise<void> {
        try {
            await this.service.call("POST", `${process.env.REACT_APP_URL_MASTER}/${process.env.REACT_APP_URL_MASTER_SERVICES}/catalogo/proveedor/cambiarContrasenia`, JSON.stringify(params), "bearer", "json", "json", { "request-decrypt-response": "yes" }, 0);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}