import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { RepositoryImplMain } from './RepositoryImplMain';
import { AdapterConfigure } from './AdapterConfigure';
import { useState } from 'react';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, changeSaludo, hideIconMenu, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { DtoRequestInvoice } from '../Domain/DtoRequestInvoice';
import { UseCaseFacturas } from '../Application/UseCaseFacturas';
import { UseCaseComparativo } from '../Application/UseCaseComparativo';
import { UseCaseTipoIVA } from '../Application/UseCaseTipoIVA';
import { DtoResponseInvoice } from '../Domain/DtoResponseInvoice';
import { MenuItem } from 'primereact/menuitem';
import { ColumnEvent } from "primereact/column";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { Reception } from "../Domain/Reception";
import { Valorization } from "../Domain/Valorization";
import { useFormik } from "formik";
import { TipoIVA } from "../Domain/TipoIVA";
import * as Yup from 'yup';

import { UseCaseTipoDocumentoTributario } from "../Application/UseCaseTipoDocumentoTributario";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";
import { FileUploadSelectEvent } from "primereact/fileupload";

import { ListaControlDocumental } from '../Domain/ListaControlDocumental';
import { DtoRequestControlDocumental} from '../Domain/DtoRequestControlDocumental';
import { UseCaseListaControlDocumental } from '../Application/UseCaseListaControlDocumental';
import { UseCaseUpdateFactura } from "../Application/UseCaseUpdateFactura";
import { UseCaseReenviarFacturaEditada } from "../Application/UseCaseReenviarFacturaEditada";
import { DataTableSelectionChangeEvent } from "primereact/datatable";

export const Controller = () => {
    //const { auth, user } = useSelector((state: RootState) => state.auth);
    const { generico: { websocket, dbLocal, countProcess }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const [showLanguage, setShowLanguage] = useState<boolean>(false);
    const { language } = AdapterStorage.get('language');
    const [data, setData] = useState<Array<DtoResponseInvoice>>([]);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const items:MenuItem[] = [{ label: 'Estado Factura',icon:'pi pi-file'}]; //items del BreadCrumb
    const home:MenuItem  = { icon: 'pi pi-home', url: '/main' } //home del BreadCrumb
    
    const [visibleBill, setVisibleBill] = useState<boolean>(false);
    const [dataBill, setdataBill] = useState<any>();
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [selectedValorizaciones, setSelectedValorizaciones] = useState<any[] | null | any>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [tipoIVA, setTipoIVA] = useState<TipoIVA[] | null>();
    const [importeAsociado, setImporteAsociado] = useState<number>(0);
    const [disabledImporteIva, setDisabledImporteIva] = useState<boolean>(true);
    const [tipoDocumentoTributario, setTipoDocumentoTributario] = useState<TipoDocumentoTributario[] | null >(null);
    const [facturaFile, setFacturaFile] = useState<FileUploadSelectEvent | null>();

    const [anexoFile, setAnexoFile] = useState<FileUploadSelectEvent[]>([]);
    const [ListaControlDocumental, setDataListaControlDocumental] = useState<Array<ListaControlDocumental>>([]);
    const [docuemntosCD, setdocuemntosCD] = useState<Array<any>>([]);
    const [DocumentosFile, setDocumentosFile] =useState<FileUploadSelectEvent | null>();
    const [pdf, setPdf] = useState<any>();
    const [visibleDocument, setVisibleDocument] = useState<boolean>(false);

    const [visibleDialogSteps, setVisibleDialogSteps] = useState<boolean>(false);

    const [dataText, setDataText] = useState<any>({
      textValorizacion: "",
    });

    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            dispatch(addLoading('Cargando datos...'));
            await loadData();
            await loadTipoIVA();
            await loadTipoDocumentoTributario();
            dispatch(removeLoading())
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
        try {
            const param: DtoRequestInvoice = {
                _id:user.usuario._id
            }
            dispatch(addLoading({ textLoading: 'Cargando...' }));
            const response = await (new UseCaseFacturas(repository).exec(param));
            if (response === null) return;
            const orderData = response.sort((a,b)=> new Date(b?.Dates.Registry.Date).getTime() - new Date(a?.Dates.Registry.Date).getTime())
            //console.log("orderData**********", orderData);
            setData(orderData);
        } catch (err) {
        } finally {
            dispatch(removeLoading());
        }

    };

    const loadTipoIVA = async () => {
        try {
          const param: any = {
            // cdPais: "504"
            cdPais: user.usuario.pais.codigo,
          };
          console.log("user****************", user)
          dispatch(addLoading({ textLoading: "Cargando..." }));
          const response = await new UseCaseTipoIVA(repository).exec(param);
    
          if (response === null || undefined) return;
          //await dbLocal.clearStore('Factura');
          //await dbLocal.insertDataStore([{ nameStore: 'Factura', data: response }]);
          let tipo = response.filter(
            (value: TipoIVA) =>
              value.VATBus_PostingGroup ===
              user.usuario.datosContabilidad.VATBus.Key
          );
          //{props.tipoIVA.filter((value: any) => value.VATBus_PostingGroup === props.user.usuario.datosContabilidad.VATBus.Key)}
          console.log("tipo iva", tipo);
          setTipoIVA(tipo);
        } catch (err) {
          //let error: ErrorCostume = new ErrorCostume((err as Error).message);
          //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
          dispatch(removeLoading());
        }
      };

      const loadTipoDocumentoTributario = async () => {
        try {
          const param: any = {
            // cdPais: "504"
            cdPais: user.usuario.pais.codigo,
          };
          dispatch(addLoading({ textLoading: "Cargando..." }));
          const response = await new UseCaseTipoDocumentoTributario(
            repository
          ).exec(param);
    
          if (response === null) return;
          //await dbLocal.clearStore('Factura');
          //await dbLocal.insertDataStore([{ nameStore: 'Factura', data: response }]);
          response.forEach(item => {
            if (item.Descripcion.length > 81) {
              item.Descripcion = item.Descripcion.substring(0, 78) + '...';
            }
        });
          console.log("response tipo tributario", response);
          setTipoDocumentoTributario(response);
        } catch (err) {
          //let error: ErrorCostume = new ErrorCostume((err as Error).message);
          //AdapterGenerico.createMessage('Alerta', error.message, 'warning', false);
        } finally {
          dispatch(removeLoading());
        }
      };
    /**
     * 
     * @param status 
     * @returns 
     * @description retorna el estado de la factura
     */
    const getStatus = (status: string|number) => {
        switch (status) {
            case 4:
                return 'danger';
            case 11:
                return 'danger';
            case 21:
                return 'info';
            case 101:
                return 'success';

            case 0:
                return 'warning';

            case 'negotiation':
                return 'warning';

            case 'renewal':
                return null;
        }
    };
    const formatCurrency = (value: number) => {
        return value?.toLocaleString('en-US',{minimumFractionDigits:2,maximumFractionDigits:2});
    };
    const TransformMoneda = (rowData: DtoResponseInvoice) => {
      return rowData.DatosPago.Moneda?.Codigo.length===0 ? 'PEN' : rowData.DatosPago?.Moneda?.Codigo;
    };
    const TransformDate = (value:string) => {
        return new Date(value).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const openVerMotivoRechazo = async (rowData:any) => {
        try {
            let mensaje = "";
            if(rowData.Dates.Reverted){
                let dataMotivo = rowData.Bitacora.filter((obj: any)=>obj.Action==='SOLICITUD REVERTIDA' && obj.Date.IdDate===rowData.Dates.Reverted.IdDate);
                if(dataMotivo.length>0){
                    mensaje = dataMotivo[dataMotivo.length-1].Description; 
                }    
            }
            await AdapterGenerico.createMessage(
                "Motivo de Rechazo",
                mensaje,
                "info"
            );
            return false;

        } catch (error) {
        } finally {
            dispatch(removeLoading());
        }
    };

    /** Region de Ingreso de factura */
    const formInvoice = useFormik({
        initialValues: {
          //step 1
          importeFactura: 0,
          //step 2
          fechaEmision: new Date().toISOString().slice(0, 10),
          importe: 0,
          numeroDocumentoTributario: "",
          serie: "",
          importeIva: 0,
          importeTotal: 0,
          tipoDocumentoTributario: null,
          tipoIva: null,
          importeAsociado: 0,
          importePendiente: 0,
          detalle: [],
          datosTrabajo: "",
          observacion: "",
          CDEmpresa: "",
        },
    
        onSubmit(values, formikHelpers) {},
    
        validationSchema: Yup.object({
          //step 1
          importeFactura: Yup.number().when([], {
            is: () => tabIndex === 0,
            then: Yup.number()
              .min(0.01, "El importe de la factura no puede ser 0")
              // .max(Yup.ref('importeAsociar'),'la factura excede el importe ')
              .required("importe requerido")
              .nullable(),
          }),
        }),
    });

    const openEditFactura = async (rowData:any) => {
        try {

            //se bucara las Valorizaciones basado en el orden en la cual se ha generado
            const param: DtoRequestComparativo = {
                _id: user.usuario._id,
                preOrden:JSON.stringify(rowData.DatosDocumento.TipoCompra.PreOrden)
            };
    
            dispatch(addLoading({ textLoading: "Cargando..." }));
            const response = await new UseCaseComparativo(repository).exec(param);
            const Recepciones: Reception[] = [];
            const RecepcionesValorizaciones: any[] = [];
            const Valorizaciones: Valorization[] = [];

            //let responseC = response?.filter((obj:any)=>obj.Codigo===rowData.DatosDocumento.TipoCompra.PreOrden[0]);
            console.log("rowData.DatosDocumento.TipoCompra.PreOrden", rowData.DatosDocumento.TipoCompra.PreOrden);
            let responseC = response?.filter((obj: any) => {
              const validCodes = rowData.DatosDocumento.TipoCompra.PreOrden;
              return validCodes.includes(obj.Codigo);
            });

            console.log("responseC", responseC);

            responseC?.map((value) => {
              if (value.MovimientosRecepcion.length !== 0) {
                value.MovimientosRecepcion.map((recepcion) => {
                  let file = null;
                  let resultFile:any = value?.Files?.Albaran?.filter(info=>info.Detalle.Code===recepcion.Code)
                  if(resultFile.length>0){
                    file = resultFile[0];
                  }
                  // value?.Files?.Albaran?.map((fil) => {
                  //   if (fil.Detalle.Code === recepcion.Code) {
                  //     file = fil;
                  //   } else {
                  //     file = null;
                  //   }
                  // });
      
                  //console.log("recepcion", recepcion.FacturaProveedor);
      
                  let sumaAsociados = 0;
                  for (const index in recepcion.FacturaProveedor) {
                    let itemAsociado = recepcion.FacturaProveedor[index];
                    if(itemAsociado.Factura===rowData.Codigo){
                      sumaAsociados += itemAsociado.importeAsociado;
                    }  
                  }

                  if(sumaAsociados!==0){
                    recepcion.Pendiente = (recepcion.Pendiente===0)?sumaAsociados:recepcion.Pendiente;
                    Recepciones.push({
                      CodigoFactura: value.Codigo,
                      Moneda:
                        value.DatosPago.Moneda.Codigo === ""
                          ? "PEN"
                          : value.DatosPago.Moneda.Codigo,
                      DatosTrabajo: value.DatosTrabajo,
                      CDEmpresa: value.DatosEmpresa.CDEmpresa,
                      // ImporteAsociar:recepcion.Pendiente,
                      //Importe:recepcion.Pendiente,
                      //Pendiente: 0,
                      ImporteAsociar: sumaAsociados,//recepcion.Pendiente - sumaAsociados,
                      TipoCompra: value.TipoCompra,
                      File: file,
                      ...recepcion,
                    });
                  }

                });
              }
      
              if (value.Valorizaciones.length !== 0) {
                value.Valorizaciones.map((valorizacion) => {
                  let file = null;
                  let resultFile:any = value?.Files?.Valorizacion?.filter(info=>info.Detalle.Code===valorizacion.Code)
                  if(resultFile.length>0){
                    file = resultFile[0];
                  }
                  // value?.Files?.Valorizacion?.map((fil) => {
                  //   if (fil.Detalle.Code === valorizacion.Code) {
                  //     file = fil;
                  //   } else {
                  //     file = null;
                  //   }
                  // });
                  // if(valorizacion?.Pendiente>0){
                  //console.log("valorizacion", valorizacion.FacturaProveedor);
      
                  let sumaAsociados = 0;
                  for (const index in valorizacion.FacturaProveedor) {
                    let itemAsociado = valorizacion.FacturaProveedor[index];
                    if(itemAsociado.Factura===rowData.Codigo){
                        sumaAsociados += itemAsociado.importeAsociado;
                    }                   
                  
                  }
                  if(sumaAsociados!==0){
                    valorizacion.Pendiente = (valorizacion.Pendiente===0)?sumaAsociados:valorizacion.Pendiente;
                    Valorizaciones.push({
                      CodigoFactura: value.Codigo,
                      Moneda:
                        value.DatosPago.Moneda.Codigo === ""
                          ? "PEN"
                          : value.DatosPago.Moneda.Codigo,
                      ImporteAsociar: sumaAsociados,
                      TipoCompra: value.TipoCompra,
                      DatosTrabajo: value.DatosTrabajo,
                      CDEmpresa: value.DatosEmpresa.CDEmpresa,
                      File: file,
                      ...valorizacion,
                    });
                  }

                  // }
                });
              }
            });
            
            //console.log("Valorizaciones", Valorizaciones);
            //console.log("Recepciones", Recepciones);
            Recepciones.map((recepcion) => {
              RecepcionesValorizaciones.push(recepcion);
            });
            Valorizaciones.map((valorizaciones) => {
              RecepcionesValorizaciones.push(valorizaciones);
            });
      
            if (responseC === null) return;
            let prueba = RecepcionesValorizaciones;
            // .filter(
            //   (value) => value?.Pendiente > 0
            // );
            // let prueba2 = prueba.map((x) => {
            //   if (x["FacturaProveedor"] === undefined) {
            //     return x;
            //   } else {
            //     //if (x.FacturaProveedor[x.FacturaProveedor.length - 1].Pendiente > 0) {
            //       return x;
            //     //}
            //   }
            // });
      
            const transformData = prueba.filter((x) => x !== undefined);
            let detalleTransform: any = [];
            transformData.map((value: any) => {
              detalleTransform.push({
                comparativo: value.CodigoFactura,
                Code: value.Code,
                Importe: value.ImporteAsociar,
              });
            });

            console.log("transformData", transformData);
            console.log("detalleTransform", detalleTransform);
            setSelectedValorizaciones(transformData);

            let sumImporteAsociado = transformData.reduce(
                (accumulator: any, current: any) =>
                  accumulator +
                  (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar),
                0
              );
            
            formInvoice.resetForm();

            formInvoice.setValues((values: any) => ({
              ...values,
              importeAsociado: sumImporteAsociado,
              importe: sumImporteAsociado,
              importeFactura: sumImporteAsociado
            }));
            
            console.log("tipoIVA", tipoIVA)
            let iva18: any = tipoIVA?.filter((obj) => obj.VATProd_PostingGroup === rowData.DatosPago.Importe.VAGProd);
            console.log("iva18______________", iva18[0]);
        
            formInvoice.setFieldValue("tipoIva", iva18[0]);
        
            if (formInvoice.values.tipoIva === null) {
              formInvoice.setFieldValue("importeTotal", sumImporteAsociado);
            }
        
            onChangeTipoIvaInit(iva18[0],sumImporteAsociado);            
            setdataBill(rowData);

            //setear datos de facturas
            formInvoice.setFieldValue("fechaEmision", new Date(rowData.Dates.Emision.Date.slice(0, 10)).toISOString().slice(0, 10));
            let TipoDocumento: any = tipoDocumentoTributario?.filter((obj) => obj.Codigo === rowData.DatosDocumento.DocumentoTributario.TipoDocumento.Codigo);
            formInvoice.setFieldValue("tipoDocumentoTributario", TipoDocumento[0]);
            formInvoice.setFieldValue("numeroDocumentoTributario", rowData.DatosDocumento.DocumentoTributario.NroDocumento);
            formInvoice.setFieldValue("observacion", rowData.DatosDocumento.Observacion);
            formInvoice.setFieldValue("serie", rowData.DatosDocumento.DocumentoTributario.Serie);
            formInvoice.setFieldValue("detalle", detalleTransform);
            
            setVisibleBill(true);
        } catch (error) {
          console.log(error)
        } finally {
            dispatch(removeLoading());
        }
    };
    const onChangeTipoIvaInit = (e: any,importe:any) => {
        //console.log("e.value,",e.value)
        //setDisabledImporteIva(true);
        formInvoice.setValues((values: any) => ({
          ...values,
          tipoIva: e,
          importeAsociado: importe,
          importeFactura:importe,
          importe: importe,
          importeIva: (e.VAT * importe) / 100,
          importeTotal:
          importe +
            (e.VAT * importe) / 100,
        }));
    
      };
    const onHideBill = () => {
      setVisibleBill(false);
      formInvoice.resetForm();
      setTabIndex(0);
      setSelectedValorizaciones([]);
      setDisabled(false);
      setFacturaFile(null);
      setAnexoFile([]);
      setdocuemntosCD([]);
      setDataListaControlDocumental([]);
    };
    //Step 1
    const onSubmit = async (e: Event) => {
        e.stopPropagation();
        //setTabIndex(tabIndex + 1);
        console.log("formInvoice.values.importe***", formInvoice.values.detalle);
        console.log("selectedValorizaciones***",selectedValorizaciones)
        if (tabIndex === 0) {
          try {
            if (
              formInvoice.values.importeAsociado === 0 ||
              formInvoice.values.importeTotal === 0
            ) {
              await AdapterGenerico.createMessage(
                "Alerta",
                "Importe incorrecto!",
                "warning"
              );
              return false;
            }
    
            if (!formInvoice.values.tipoIva) {
              await AdapterGenerico.createMessage(
                "Alerta",
                "Debe seleccionar un Tipo IVA!",
                "warning"
              );
              return false;
            }


            //Aqui se debe calcular entre l cantidad total de pendientes.            
            //console.log("selectedValorizaciones xxxx", selectedValorizaciones);            
            for(let recepcion of selectedValorizaciones){

              let sumaAsociado = 0;
              
              if(recepcion.FacturaProveedor){
                for(let fact of recepcion.FacturaProveedor){
                  if(fact.Factura!==dataBill.Codigo){
                    let flatingreso = 0;
                    if(recepcion.FacturasAsociados){
                      let items_:any = recepcion.FacturasAsociados.filter((a:any)=>a.Factura===fact.Factura);
                      if(items_.length>0){
                        flatingreso = 1;
                      }
                    }
                    if(flatingreso===0){
                      sumaAsociado += fact.importeAsociado;
                    }
                    //sumaAsociado+=fact.importeAsociado
                  
                  }
                }
              }

              let importePendiente = recepcion.Pendiente;//recepcion.Pendiente - sumaAsociado;
              let valDetalle:any = formInvoice.values.detalle.filter((obj:any)=>obj.Code===recepcion.Code);

              if(valDetalle.length>0){    
                  if (valDetalle[0].Importe > importePendiente) {
                    await AdapterGenerico.createMessage(
                      "Alerta",
                      "El importe "+valDetalle[0].Importe+" debe ser menor o igual al importe "+importePendiente+" generado",
                      "warning"
                    );
                    return false;
                  }
              }
             
            }
            setTabIndex((tabIndex) => {
              return tabIndex + 1;
            });
          } catch (error) {}

          if(ListaControlDocumental.length===0){
            await loadDocumentoFacturas(dataBill);
          }
                    
        } else {
            //console.log("tabIndex", tabIndex);
            setTabIndex(tabIndex);
            if (
              !formInvoice.values.tipoDocumentoTributario ||
              formInvoice.values.tipoDocumentoTributario == null
            ) {
              setTabIndex(tabIndex);
              await AdapterGenerico.createMessage(
                "Alerta",
                "Debe seleccionar tipo documento tributario",
                "warning"
              );
              return false;
            }

            if (
              !formInvoice.values.serie ||
              formInvoice.values.serie == null
            ) {
              setTabIndex(tabIndex);
              await AdapterGenerico.createMessage(
                "Alerta",
                "Debe ingresar serie",
                "warning"
              );
              return false;
            }
      
            if (
              !formInvoice.values.numeroDocumentoTributario ||
              formInvoice.values.numeroDocumentoTributario == null
            ) {
              setTabIndex(tabIndex);
              await AdapterGenerico.createMessage(
                "Alerta",
                "Debe ingresar numero documento tributario",
                "warning"
              );
              return false;
            }
      
            if (
              !formInvoice.values.observacion ||
              formInvoice.values.observacion == null
            ) {
              setTabIndex(tabIndex);
              await AdapterGenerico.createMessage(
                "Alerta",
                "Debe ingresar observacion",
                "warning"
              );
              return false;
            }
      
      
            for (const doc in ListaControlDocumental) {
              let obligatoriedad = ListaControlDocumental[doc].Obligatoriedad;
              let documento = ListaControlDocumental[doc].Documento;
              
              if(ListaControlDocumental[doc].EstadoCarga==='Pendiente'){
                if (obligatoriedad === "SI") {
                  let fileUp = docuemntosCD.filter(
                    (objFile) => objFile.codigo === documento
                  );
                  console.log("fileUp", fileUp);
                  //alert(fileUp.length)
                  if (fileUp.length === 0) {
                    setTabIndex(tabIndex);
                    await AdapterGenerico.createMessage(
                      "Alerta",
                      "Debe cargar una " + documento + ".",
                      "warning"
                    );
                    return false;
                  }
                }
              }

            }
            dispatch(addLoading({ textLoading: "Cargando..." }));
      
            console.log("formInvoice.values.tipoDocumentoTributario", formInvoice.values.tipoDocumentoTributario);
            console.log("facturaFile", facturaFile);
            console.log("anexoFile", anexoFile);
            console.log("docuemntosCD", docuemntosCD);
            console.log("formInvoice.values.detalle", formInvoice.values.detalle);
            const response = await new UseCaseUpdateFactura(repository).exec({
              _idProveedor: user.usuario._id,
              importeTotal: formInvoice.values.importeTotal,
              importeIva: "" + formInvoice.values.importeIva,
              tipoIvaKey:
                formInvoice.values.tipoIva !== null
                  ? formInvoice.values.tipoIva["VATProd_PostingGroup"]
                  : "",
              observacion: formInvoice.values.observacion,
              numeroDocumentoTributario: formInvoice.values.numeroDocumentoTributario,
              serie: formInvoice.values.serie,
              tipoDocumentoTributario: JSON.stringify(formInvoice.values.tipoDocumentoTributario)
                // formInvoice.values.tipoDocumentoTributario !== null
                //   ? formInvoice.values.tipoDocumentoTributario["Codigo"]
                //   : ""
              ,
              importe: "" + formInvoice.values.importeFactura,
              iva:
                formInvoice.values.tipoIva !== null
                  ? "." + formInvoice.values.tipoIva["VAT"]
                  : "",
              monedaKey: "",
              fechaEmision:JSON.stringify(formInvoice.values.fechaEmision),
              datosTrabajo: formInvoice.values.datosTrabajo,
              detalle: JSON.stringify(formInvoice.values.detalle), //formInvoice.values.detalle
              factura: facturaFile,
              anexo01: anexoFile,
              CDEmpresa: formInvoice.values.CDEmpresa,
              docuemntosCD: docuemntosCD,
              codigo:dataBill.Codigo,
              preOrden:dataBill.DatosDocumento.TipoCompra.PreOrden[0]
              // detalle:
              // , archivos: info.archivos.filter(x => x.estado === 'pendiente')
            });
      
            onHideBill();
            formInvoice.resetForm();
      
          await loadData();
          dispatch(removeLoading());
    
          return;
        }
    
    };

    const loadDocumentoFacturas = async (data: any) => {
      console.log("preorden", data);
      try {
        dispatch(addLoading({ textLoading: "Cargando..." }));
        //console.log("user", user)
        const param: DtoRequestControlDocumental = {
          _id: user.usuario._id,
          pais: {
            codigo: data.DatosPais.CDPais,
            nombre: data.DatosPais.CDPais,
          }, //user.usuario.pais, //Pais del el docuemtno de PreOrden
          delegacion: {
            codigo: data.DatosTrabajo.Delegacion.Codigo,
            key: data.DatosTrabajo.Delegacion.Codigo,
            nombre: data.DatosTrabajo.Delegacion.Codigo,
          }, //user.usuario.datosTrabajo.delegacion,//Delegacion del el docuemtno de PreOrden
          ot: user.usuario.datosTrabajo.ot,
          "Supplier classification": user.usuario["Supplier classification"],
          Tipo: "Facturas",
        };
        //console.log("ata.datosTrabajo.Delegacion.Codigo",data.datosTrabajo.Delegacion.Codigo)
        //console.log("param", param)
        let estadoCargaFactura = "Pendiente";
        if(data.Files.PreRegistroCompra){
          let statusDocFac = data.Files.PreRegistroCompra.filter((obj:any)=>obj.Status.IdStatus===1);
          if(statusDocFac.length>0){
            estadoCargaFactura = "Cargado";
          }
        }

        let response_doc: Array<ListaControlDocumental> = [];
        let itemsFact = {
          IdDocumento: 0,
          Codigo: "Factura0",
          Documento: "Factura",
          TipoDocumento: {
            Code: "CD0002-3",
            Name: "Facturas",
          },
          Renovacion: {
            Periocidad: "Anio",
            Cantidad: 1,
          },
          Aviso: {
            Periocidad: "Meses",
            Cantidad: 1,
          },
          TiempoGracia: {
            Periocidad: "Dias",
            Cantidad: 15,
          },
          Obligatoriedad: "SI",
          Users: {
            Registry: {
              IdUser: 957,
              User: "JCARBAJALE",
              LastName: "CARBAJAL ELESCANO",
              Name: "JORGE ALEJANDRO",
              Perfil: "SISTEMAS",
            },
            Update: {
              IdUser: 957,
              User: "JCARBAJALE",
              LastName: "CARBAJAL ELESCANO",
              Name: "JORGE ALEJANDRO",
              Perfil: "SISTEMAS",
            },
          },
          Status: {
            IdStatus: 1,
            Status: "ACTIVO",
          },
          EstadoCarga: estadoCargaFactura,
          EstadoVencimiento: estadoCargaFactura,
          FechaVencimiento: "",
          Operacion: "",
          FechaCarga: "",
          Files: "",
          Proveedor: {
            Codigo: 205365,
            TipoOperacion: "",
          },
        };

        let estadoCargaAnexo = "Pendiente";
        if(data.Files.AnexoFactura){
          let statusDocFac = data.Files.AnexoFactura.filter((obj:any)=>obj.Status.IdStatus===1);
          if(statusDocFac.length>0){
            estadoCargaAnexo = "Cargado";
          }
        }
        let itemsAnexo = {
          IdDocumento: 0,
          Codigo: "Anexos0",
          Documento: "Anexos",
          TipoDocumento: {
            Code: "CD0002-3",
            Name: "Facturas",
          },
          Renovacion: {
            Periocidad: "Anio",
            Cantidad: 1,
          },
          Aviso: {
            Periocidad: "Meses",
            Cantidad: 1,
          },
          TiempoGracia: {
            Periocidad: "Dias",
            Cantidad: 15,
          },
          Obligatoriedad: "NO",
          Users: {
            Registry: {
              IdUser: 957,
              User: "JCARBAJALE",
              LastName: "CARBAJAL ELESCANO",
              Name: "JORGE ALEJANDRO",
              Perfil: "SISTEMAS",
            },
            Update: {
              IdUser: 957,
              User: "JCARBAJALE",
              LastName: "CARBAJAL ELESCANO",
              Name: "JORGE ALEJANDRO",
              Perfil: "SISTEMAS",
            },
          },
          Status: {
            IdStatus: 1,
            Status: "ACTIVO",
          },
          EstadoCarga: estadoCargaAnexo,
          EstadoVencimiento: estadoCargaAnexo,
          FechaVencimiento: "",
          Operacion: "",
          FechaCarga: "",
          Files: "",
          Proveedor: {
            Codigo: 205365,
            TipoOperacion: "",
          },
        };
        response_doc.push(itemsFact);
        response_doc.push(itemsAnexo);
        const response_doc_i = await new UseCaseListaControlDocumental(
          repository
        ).exec(param);
        response_doc_i?.map((doc) => {
          if(data.Files.ControlDocumental){
            let statusDocFac = data.Files.ControlDocumental.filter((obj:any)=>obj.Name===doc.Documento.toUpperCase()+".PDF" && obj.Status.IdStatus===1);
            if(statusDocFac.length>0){
              doc.EstadoCarga = "Cargado";
            }
            response_doc.push(doc);
          }
        });
  
        if (response_doc === null) return;
        setDataListaControlDocumental(response_doc);
        console.log("ListaControlDocumental---", ListaControlDocumental)
        dispatch(removeLoading());

        return response_doc;

      } catch (error) {
        console.log(error)
      } finally {
        dispatch(removeLoading());
      }
    };
    const formatCurrenc_step = (value: number, moneda: string) => {
        return value?.toLocaleString("en-US", {
          style: "currency",
          currency: moneda,
        });
    };

    const formatDate = (value: string | Date) => {
        return new Date(value).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
    };

    const onChange = (name: string, value: any) => {
        if (value === null) {
          return;
        }
        formInvoice.setFieldValue(name, value);
    };

    const onChangeTipoIva = (e: any) => {
        //console.log("e.value,",e.value)
        setDisabledImporteIva(true);
        formInvoice.setValues((values: any) => ({
            ...values,
            tipoIva: e.value,
            importeIva: (e.value.VAT * formInvoice.values.importe) / 100,
            importeTotal:
            formInvoice.values.importe +
            (e.value.VAT * formInvoice.values.importe) / 100,

            //     importeAsociado:sumImporteAsociado,
            //     importe:sumImporteAsociado,
            //     importeFactura:sumImporteAsociado,
        }));
        if (e.value.CodSunat === "" && e.value.VATProd_PostingGroup === "OTROS") {
            setDisabledImporteIva(false);
        }
        if (
            e.value.CodSunat === "" &&
            e.value.VATProd_PostingGroup === "EXONERADO"
        ) {
            setDisabledImporteIva(true);
            formInvoice.setValues((values: any) => ({
            ...values,
            tipoIva: e.value,
            importeIva: 0,
            importeTotal:
                formInvoice.values.importe +
                (e.value.VAT * formInvoice.values.importe) / 100,

            //     importeAsociado:sumImporteAsociado,
            //     importe:sumImporteAsociado,
            //     importeFactura:sumImporteAsociado,
            }));
        }
    };

    const onChangeValueImporteIva = (e: any) => {
        formInvoice.setValues((values: any) => ({
          ...values,
          importeTotal: e.value + formInvoice.values.importe,
        }));
    };

    const onSelectionChange = (e: DataTableSelectionChangeEvent<any[]>) => {
      let detalleTransform: any = [];
  
      e.value?.map((value: any) => {
        detalleTransform.push({
          comparativo: value.CodigoFactura,
          Code: value.Code,
          Importe: value.Pendiente,
        });
      });
      formInvoice.setValues((values: any) => ({
        ...values,
        detalle: detalleTransform,
        datosTrabajo: e?.value[0]?.DatosTrabajo,
        CDEmpresa: e?.value[0]?.CDEmpresa,
      }));
  
      setSelectedValorizaciones(e.value);
    };

    const onCellEditCompleteImporte = (e: ColumnEvent) => {
        let { rowData, newValue, field, originalEvent: event } = e;
    
        let sumImporteAsociado = 0;
        let transform: any = [];
        switch (field) {
          case "ImporteAsociar":
            //props.formInvoice.setFieldValue('importeAsociado',newValue),
            setImporteAsociado(newValue);
    
            rowData[field] = newValue;
    
            transform = formInvoice.values.detalle?.map((value: any) => {
              if (value?.Code === rowData.Code) {
                return {
                  ...value,
                  Importe: newValue,
                };
              } else {
                return value;
              }
            });
    
            break;
          default:
            if (newValue.trim().length > 0) rowData[field] = newValue;
        }
        sumImporteAsociado = selectedValorizaciones.reduce(
          (accumulator: any, current: any) =>
            accumulator +
            (current?.ImporteAsociar === undefined ? 0 : current?.ImporteAsociar),
          0
        );
        formInvoice.setValues((values: any) => ({
          ...values,
          importeAsociado: sumImporteAsociado,
          importe: sumImporteAsociado,
          importeFactura: sumImporteAsociado,
          importeTotal:
            formInvoice.values.tipoIva === null
              ? sumImporteAsociado
              : sumImporteAsociado +
                (formInvoice.values.tipoIva["VAT"] * sumImporteAsociado) / 100,
          importeIva:
            formInvoice.values.tipoIva === null
              ? 0
              : (formInvoice.values.tipoIva["VAT"] * sumImporteAsociado) / 100,
          detalle: transform,
        }));
    
      };
    const itemsDialogSteps: MenuItem[] = [
        {
          label: "Asociación",
          command: () => {
            setTabIndex(0);
          },
        },
        {
          label: "Factura",
          command: () => {
            setTabIndex(1);
          },
        },
      ];
      // const onHideDialogSteps = () => {
      //   setVisibleBill(false);
      //   formInvoice.resetForm();
      //   setTabIndex(0);
      //   setSelectedValorizaciones([]);
      //   setDisabled(false);
      //   setFacturaFile(null);
      //   setAnexoFile([]);
      //   setdocuemntosCD([]);
      // };

    const onSelectAnexo = (e: any) => {
      let listaFile: any = [];
  
      console.log("filexxxx", e.length);
  
      for (const file of e) {
        console.log("file****", file);
        if (typeof file === "object") {
          if (file.size <= 50000000) {
            listaFile.push(file);
          }
        }
      }
  
      if (anexoFile.length > 0) {
        for (const file in anexoFile) {
          if (typeof anexoFile[file] === "object") {
            listaFile.push(anexoFile[file]);
          }
        }
      }
      console.log("onSelectAnexo listaFile", listaFile);
  
      setAnexoFile(listaFile);
    };
    
  const onRemoveAnexo = (e: any) => {
    const archivoRemovido = e.file;

    const listFileRemove = anexoFile.filter(
      (file: any) => file.name !== archivoRemovido.name
    );

    setAnexoFile(listFileRemove);
  };
  const onClearAnexo = (e: any) => {
    setAnexoFile([]);
  };

  const onSelectDocuementoFactura = (e: any, documento: any) => {
    // Obtener el archivo seleccionado
    const file: FileUploadSelectEvent = e.files[0];
    const size = e.files[0].size;
    if (size <= 50000000) {
      let listaFile = [];

      let items = {
        file: file,
        codigo: documento,
      };

      listaFile.push(items);

      if (docuemntosCD.length > 0) {
        for (const file in docuemntosCD) {
          listaFile.push(docuemntosCD[file]);
        }
      }
      console.log("cool", listaFile);
      setdocuemntosCD(listaFile);
    } else {
      console.log("cool", "maximo 50000000");
    }
  };
  const onRemoveDocuementoFactura = (e: any) => {
    const archivoRemovido = e.file;

    const listFileDocRemove = docuemntosCD.filter(
      (file: any) => file.name !== archivoRemovido.name
    );
    console.log("listFileDocRemove", listFileDocRemove);

    setdocuemntosCD(listFileDocRemove);
  };

  const onClearDocuementoFactura = (e: any) => {
    setdocuemntosCD([]);
  };
  const fileToBlob = async (file: File) => {
    const blob = file.slice(0, file.size, file.type);
    return blob;
  };

  const convertBlobToBase64 = async (blob: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = async () => {
          let result = (await reader.result?.toString()) || "";

          resolve(
            result.replace(
              "data:application/octet-stream;base64,",
              "data:application/pdf;base64,"
            )
          );
          //resolve(result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        reject(error);
      }
    });
  };
  const verDocumento = async (rowData: any) => {
    try {
      console.log("ver docuemntosCD", docuemntosCD);
      console.log("ver rowData", rowData);
      console.log("ver dataBill", dataBill);

      let dataFile = docuemntosCD.filter(
        (obj: any) => obj.codigo === rowData.Documento // Asegúrate de especificar el tipo de `obj`
      );
      
      console.log("dataFile", dataFile);
      dispatch(addLoading({ textLoading: 'Cargando...' }));
      if(dataFile.length>0){
        const blob_: Blob = await fileToBlob(dataFile[0].file); // Añade el tipo `Blob` a la variable

        let type = "base64";
        let result;

        switch (type) {
          case "blob":
            result = blob_;
            break;
          case "urlBlob":
            result = URL.createObjectURL(blob_);
            break;
          case "base64":
            result = await convertBlobToBase64(blob_);
            break;
          default:
            break;
        }
        setPdf(result);
        setDataText({
          textValorizacion: `Documento: ${rowData.Documento.toUpperCase()}`
        });
        setVisibleDocument(true);
        
      }else{
        if(rowData.Codigo==='Factura0'){
          if(dataBill.Files.PreRegistroCompra){
            let statusDocFac = dataBill.Files.PreRegistroCompra.filter((obj:any)=>obj.Status.IdStatus===1);
            const requestOptions = {
              "method": 'POST',
              "headers": {
                  'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                  'request-decrypt-response': 'yes',
                  'Content-Type': 'application/json',
              },
              "body": JSON.stringify({ "directory": statusDocFac[0].Directory,
              "fileName":`${statusDocFac[0].LoadName}`,
              user:null }),
            };
            const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
            const document = await fetch(urlDev, requestOptions);

            if(document.ok){
              let  type = 'base64';
              let blob = await document.blob();
              let result;
    
              switch (type) {
                  case 'blob':
                      result = blob;
                      break;
                  case 'urlBlob':
                      result = URL.createObjectURL(blob);
                      break;
                  case 'base64':
                      result = await convertBlobToBase64(blob);
                      break;
                  default:
                      break;
              };
              setPdf(result);
              setDataText({
                textValorizacion: `Documento: ${rowData.Documento.toUpperCase()}`
              });
              setVisibleDocument(true);
            }
          }

        }else if(rowData.Codigo==='Anexos0'){
          
          if(dataBill.Files.AnexoFactura){
            let statusDocFac = dataBill.Files.AnexoFactura.filter((obj:any)=>obj.Status.IdStatus===1);
            const requestOptions = {
              "method": 'POST',
              "headers": {
                  'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                  'request-decrypt-response': 'yes',
                  'Content-Type': 'application/json',
              },
              "body": JSON.stringify({ "directory": statusDocFac[0].Directory,
              "fileName":`${statusDocFac[0].LoadName}`,
              user:null }),
            };
            const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
            const document = await fetch(urlDev, requestOptions);

            if(document.ok){
              let  type = 'base64';
              let blob = await document.blob();
              let result;
    
              switch (type) {
                  case 'blob':
                      result = blob;
                      break;
                  case 'urlBlob':
                      result = URL.createObjectURL(blob);
                      break;
                  case 'base64':
                      result = await convertBlobToBase64(blob);
                      break;
                  default:
                      break;
              };
              setPdf(result);
              setDataText({
                textValorizacion: `Documento: ${rowData.Documento.toUpperCase()}`
              });
              setVisibleDocument(true);
            }
          }


        }else{
          if(dataBill.Files.ControlDocumental){
            let statusDocFac = dataBill.Files.ControlDocumental.filter((obj:any)=>obj.Name===rowData.Documento.toUpperCase()+".PDF" && obj.Status.IdStatus===1);
            const requestOptions = {
                "method": 'POST',
                "headers": {
                    'authorization': `Basic dXNlci5naWE6Z3I4cDBDMGJyNCEhJA==`,
                    'request-decrypt-response': 'yes',
                    'Content-Type': 'application/json',
                },
                "body": JSON.stringify({ "directory": statusDocFac[0].Directory,
                "fileName":`${statusDocFac[0].LoadName}`,
                user:null }),
            };
            const urlDev = AdapterConfigure.URL_FILES_DOWNLOAD;
            const document = await fetch(urlDev, requestOptions);

            if(document.ok){
              let  type = 'base64';
              let blob = await document.blob();
              let result;
    
              switch (type) {
                  case 'blob':
                      result = blob;
                      break;
                  case 'urlBlob':
                      result = URL.createObjectURL(blob);
                      break;
                  case 'base64':
                      result = await convertBlobToBase64(blob);
                      break;
                  default:
                      break;
              };
              setPdf(result);
              setDataText({
                textValorizacion: `Documento: ${rowData.Documento.toUpperCase()}`
              });
              setVisibleDocument(true);
            }
        
        }
        }

      }

    } catch (error) {
    } finally {
      dispatch(removeLoading());
    }
  };

  const CargarFileControlDocumental = async (
    file: any,
    parametroCodigo: string,
    parametroDocumento: any
  ) => {
    try {
      if (parametroCodigo === "Factura0") {
        setFacturaFile(file);
      } else if (parametroCodigo === "Anexos0") {
        onSelectAnexo(file);
      }

      let size = 0;
      let fileenvio: any;
      if (parametroCodigo === "Anexos0") {
        size = file[0].size;
        fileenvio = file[0];
      } else {
        size = file.size;
        fileenvio = file;
      }

      if (size < 50000000) {
        let listaFile = [];

        let items = {
          file: fileenvio,
          codigo: parametroDocumento,
        };
        listaFile.push(items);
        if (docuemntosCD.length > 0) {
          for (const file in docuemntosCD) {
            listaFile.push(docuemntosCD[file]);
          }
        }
        console.log("cool", listaFile);
        setdocuemntosCD(listaFile);
        let listaDoc: ListaControlDocumental[] | null =
          ListaControlDocumental.map((obj) => {
            console.log("obj.Codigo ", obj.Codigo);
            if (obj.Codigo === parametroCodigo) {
              obj.EstadoCarga = "Cargado";
            }
            return obj; // Asegúrate de devolver el objeto modificado
          });
        console.log("listaDoc****************", listaDoc);
        setDataListaControlDocumental(listaDoc);
      } else {
        console.log("cool", "maximo 50000000");
      }
    } catch (error) {
      console.log(error);
    } finally {
      //dispatch(removeLoading());
    }
  };
  const eliminarDocumento = async (data: any) => {
    try {
      console.log("Eliminar.data", data);
      //alert(data.Codigo)
      if (data.Codigo === "Factura0") {
        setFacturaFile(null);
      }

      if (data.Codigo === "Anexos0") {
        setAnexoFile([]);
      }

      let listaDocCargar: Array<any> = docuemntosCD.map((obj) => {
        if (obj.codigo === data.Documento) {
          obj.file = [];
          obj.codigo = "";
        }
        return obj;
      });
      setdocuemntosCD(listaDocCargar);
      let listaDoc: ListaControlDocumental[] | null =
        ListaControlDocumental.map((obj) => {
          console.log("obj.Codigoxxxx", obj.Codigo);
          if (obj.Codigo === data.Codigo) {
            obj.EstadoCarga = "Pendiente";
          }
          return obj; // Asegúrate de devolver el objeto modificado
        });
      console.log("listaDoceleiinar****************", listaDoc);
      setDataListaControlDocumental(listaDoc);
    } catch (error) {
    } finally {
      dispatch(removeLoading());
    }
  };
  
  const AlertSizeFileUpload = () => {
    AdapterGenerico.createMessage(
      "Alerta",
      "El Archivo debe pesar maximo 50M!",
      "warning",
      false
    );
  };

  const AlertTypeFileUpload = () => {
    AdapterGenerico.createMessage(
      "Alerta",
      "Debe subir un archivo PDF!",
      "warning",
      false
    );
  };
  const onHideDocument = () => {
    setVisibleDocument(false);
  };

  
  const openEnviarDevolucion = async (data: any) => {
    try {
      //console.log("data.Codigo", data)
      let confirmacion: boolean = await AdapterGenerico.createMessage('Confirmación', '¿Está seguro de reenviar la factura?', 'question', true, '.sliderbarGLPI');
      if (!confirmacion) return;
      dispatch(addLoading({ textLoading: 'Cargando...' }));
      //console.log("Envio***************")
      //await (new UseCaseReenviarFacturaEditada(repository)).exec({ 
      await new UseCaseReenviarFacturaEditada(repository).exec({
          _id: user.usuario._id,
          CodigoFactura:data.Codigo
      });
      await loadData();
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch(removeLoading());
    }
  };
  return {
    init,
    loadData,
    countProcess,
    data,
    globalFilter,
    setGlobalFilter,
    home,
    items,
    getStatus,
    formatCurrency,
    TransformMoneda,
    TransformDate,
    openEditFactura,
    onHideBill,
    visibleBill,
    dataBill,
    setTabIndex,
    tabIndex,
    itemsDialogSteps,
    onSubmit,
    formatCurrenc_step,
    formatDate,
    onChange,
    setSelectedValorizaciones,
    selectedValorizaciones,
    disabled,
    tipoIVA,
    onChangeTipoIva,
    onChangeValueImporteIva,
    onCellEditCompleteImporte,
    disabledImporteIva,
    openVerMotivoRechazo,
    setImporteAsociado,
    formInvoice,
    tipoDocumentoTributario,
    setFacturaFile,
    //onHideDialogSteps,

    setAnexoFile,
    onSelectAnexo,
    onRemoveAnexo,
    onClearAnexo,
    ListaControlDocumental,
    onSelectDocuementoFactura,
    onRemoveDocuementoFactura,
    onClearDocuementoFactura,

    verDocumento,
    CargarFileControlDocumental,
    setDocumentosFile,
    eliminarDocumento,
    AlertSizeFileUpload,
    AlertTypeFileUpload,
    pdf,
    visibleDocument,
    onHideDocument,
    dataText,
    onSelectionChange,openEnviarDevolucion

  }
}