import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
//import { EntityMain } from "../Domain/EntityMain";

import { DtoRequestInvoice } from "../Domain/DtoRequestInvoice";
import { DtoResponseInvoice } from "../Domain/DtoResponseInvoice";

import { Comparativo } from "../Domain/Comparativo";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";

import { TipoIVA } from "../Domain/TipoIVA";
import { Buffer } from 'buffer'
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";

import { DtoRequestControlDocumental } from "../Domain/DtoRequestControlDocumental";
import { ListaControlDocumental } from "../Domain/ListaControlDocumental";

import { DtoRequestInsertFactura } from "../Domain/DtoRequestInsertFactura";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async getList(params: DtoRequestInvoice): Promise<DtoResponseInvoice[] | null> {
        return this._getList(params);
    }
    private async _getList(params: DtoRequestInvoice): Promise<DtoResponseInvoice[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectFacturas`, newParams, 'bearer', "json", "json", {}, 0);
    }

    public async getListC(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        return this._getListC(params);
    }
    private async _getListC(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectComparativos`, newParams, 'bearer', "json", "json", {}, 0);
    }

    public async getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        return this._getTipoIVA(params);
    }
    private async _getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoIva/select`, newParams, 'bearer', "json", "json", {}, 0);
    }

    public async getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        return this._getDatosTributarios(params);
    }
    private async _getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoDocumentoTributario/select`, newParams, 'bearer', "json", "json", {}, 0);
    }

    public async getListControlDocumental(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null> {
        return this._getListControlDocumental(params);
    }
    private async _getListControlDocumental(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectListaControlDocumental`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async updateFactura(params:DtoRequestInsertFactura):Promise<any>{
        return this._updateFactura(params);
    }
    private async _updateFactura(params:DtoRequestInsertFactura):Promise<any>{
        let myHeaders = new Headers();
        myHeaders.append("request-decrypt-response", "yes");
        myHeaders.append("Authorization", `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}`);
        let formdata = new FormData();
        formdata.append("_idProveedor", params._idProveedor);
        formdata.append("tipoDocumentoTributario", params.tipoDocumentoTributario);
        formdata.append("monedaKey", params.monedaKey);
        formdata.append("numeroDocumentoTributario", params.numeroDocumentoTributario);
        formdata.append("serie", params.serie);
        formdata.append("importe", params.importe);
        formdata.append("iva", params.iva);
        formdata.append("tipoIvaKey", params.tipoIvaKey);
        formdata.append("importeIva", params.importeIva);
        formdata.append("importeTotal", params.importeTotal);
        formdata.append("observacion", params.observacion);
        formdata.append('datosTrabajo',params.datosTrabajo);
        formdata.append("detalle", params.detalle);
        formdata.append("factura",params.factura);
        formdata.append("CDEmpresa",params.CDEmpresa);
        formdata.append("codigo",params.codigo);
        formdata.append("preOrden",params.preOrden);
        formdata.append("fechaEmision",params.fechaEmision);        
        //docuemntosCD
        //
        params.anexo01?.forEach((archivo:any, index:any) => {
            formdata.append(`factura${index}`, archivo);
        });
        //console.log("params.docuemntosCD", params.docuemntosCD)
        params.docuemntosCD?.forEach((archivo:any, index:any) => {
            //console.log("archivo", archivo);
            if(archivo.codigo!=="Anexos" && archivo.codigo!=="Factura"){
                if(archivo.codigo!=""){
                    formdata.append(`CD-${archivo.codigo}`, archivo.file);
                }
            }

        });

        const response =await fetch(`${this.urlBase}/catalogo/proveedor/updateFactura`,{
            method:'post',
            body: formdata,
            headers: {
                "Authorization": "Basic U2lzdGVtYUdJQTpTb3BvcnRlOTMxMg==",
                "request-decrypt-response": "yes",
            },
        });
        return response;
        
    }
    
    public async getReenviarFacturaEditada(params: any): Promise<any> {
        return this._getReenviarFacturaEditada(params);
    }
    private async _getReenviarFacturaEditada(params: any): Promise<any> {
        const newParams = JSON.stringify(params);
        console.log("newParams", newParams)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/reenviarFacturaEditada`, newParams, 'bearer', "json", "json", {}, 0);
    }
    
}