import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
//import { EntityMain } from "../Domain/EntityMain";

import { DtoRequesFindByName } from "../Domain/DtoRequesFindByName";
import { DtoResponseFindByName } from "../Domain/DtoResponseFindByName";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async finByName(params: DtoRequesFindByName): Promise<DtoResponseFindByName> {
        let data :any= await this.service.call<any>("POST", `${process.env.REACT_APP_URL_MASTER_GLPI}/Ticket/findByName`, JSON.stringify(params), "bearer", "json", "json", {"request-decrypt-response": "yes"}, 0);
        return data;
    }
}