import { AdapterConfigure } from './AdapterConfigure';
import { RepositoryImplMain } from './RepositoryImplMain';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { changeSaludo, hideIconMenu, removeLoading, addLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { useState } from 'react';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';
import { UseCaseChangePassword } from '../Application/UseCaseChangePassword';
import { DtoReqChangePassword } from '../Domain/DtoReqChangePassword';

export const Controller = () => {
    const { auth, user } = useSelector((state: RootState) => state.auth);
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [showLanguage, setShowLanguage] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [showChangePasswordConfirmation, setShowChangePasswordConfirmation] = useState<boolean>(false);
    const { language } = AdapterStorage.get('language');

    const formChangePassword = useFormik({
        initialValues: {
            newPassword: '',
            reNewPassword: '',
        } as any,
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Ingresar Contraseña Actual'),
            reNewPassword: Yup.string().required('Ingresar Contraseña Nueva'),
            // reNewPassword: Yup.string().required('Ingresar Repetir Nueva Contraseña').oneOf([Yup.ref('newPassword'), null], 'La contraseña deben coincidir')
        }),

        onSubmit: (values, formikHelpers) => { },
    });


    const init = async () => {
        try {
            dispatch(changeSaludo(false));
            dispatch(hideIconMenu());
            // dispatch(addLoading('Cargando datos...'));
            await loadData();
            // dispatch(removeLoading())
        } catch (error) {
            dispatch(removeLoading())
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning');
        }
    };

    const loadData = async () => {
    };

    const onChangeLanguage = async (code: string) => {
        AdapterStorage.set('language', code);
        window.location.reload();
    };

    const onChangePassword = async (name: string, value: any) => {
        formChangePassword.setFieldValue(name, value);
    };

    const onSubmitChangePassword = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formChangePassword.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formChangePassword.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: 'Cambiando Contraseña...' }));
            const param: DtoReqChangePassword = {
                _id: user.usuario._id,
                passAnterior: formChangePassword.values.newPassword,
                passNueva: formChangePassword.values.reNewPassword,
            }

            await (new UseCaseChangePassword(repository)).exec(param);
            formChangePassword.resetForm();
            AdapterGenerico.createMessage('Exitoso', 'Se realizó el cambio de contraseña correctamente', 'success');
            dispatch(removeLoading());
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        }
    }

    return {
        init,
        auth,
        user,

        showLanguage,
        setShowLanguage,
        language,
        onChangeLanguage,

        showChangePassword,
        setShowChangePassword,
        formChangePassword,
        onChangePassword,
        onSubmitChangePassword,
        showChangePasswordConfirmation,
        setShowChangePasswordConfirmation
    };
}