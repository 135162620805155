import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityMain } from "../Domain/EntityMain";
import { AdapterConfigure } from "./AdapterConfigure";
import { Buffer } from 'buffer'

export class RepositoryImplMain extends RepositoryImplGenerico<EntityMain> implements RepositoryMain {
    public async logout(accessToken: string): Promise<void> {
        await this._logout(accessToken);
    }

    private async _logout(accessToken: string): Promise<void> {
        await this.service.bgCall<void>("POST", AdapterConfigure.URL_LOGOUT, JSON.stringify({ accessToken }), "basic", "json", 'json', {"request-decrypt-response": "yes", Authorization: `Basic ${Buffer.from(AdapterConfigure.AUTH_BASIC_USER + ':' + AdapterConfigure.AUTH_BASIC_PASS).toString('base64')}`}, 0);
        // await this.dbLocal.clearStore(['Comparativo', 'Factura']);
    }
}