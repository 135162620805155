import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { Comparativo } from "../Domain/Comparativo";
import { DtoRequestComparativo } from "../Domain/DtoRequestComparativo";
import { DtoRequestInsertFactura } from "../Domain/DtoRequestInsertFactura";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { TipoDocumentoTributario } from "../Domain/TipoDocumentoTributario";
import { TipoIVA } from "../Domain/TipoIVA";
import { Buffer } from 'buffer'
import { DtoRequestControlDocumental } from "../Domain/DtoRequestControlDocumental";
import { ListaControlDocumental } from "../Domain/ListaControlDocumental";
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async getList(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        return this._getList(params);
    }
    private async _getList(params: DtoRequestComparativo): Promise<Comparativo[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectComparativos`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        return this._getDatosTributarios(params);
    }
    private async _getDatosTributarios(params: any): Promise<TipoDocumentoTributario[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoDocumentoTributario/select`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        return this._getTipoIVA(params);
    }
    private async _getTipoIVA(params: any): Promise<TipoIVA[] | null> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/tipoIva/select`, newParams, 'bearer', "json", "json", {}, 0);
    }
    public async insertFactura(params:DtoRequestInsertFactura):Promise<any>{
        return this._insertFactura(params);
    }

    private async _insertFactura(params:DtoRequestInsertFactura):Promise<any>{
        let myHeaders = new Headers();
        myHeaders.append("request-decrypt-response", "yes");
        myHeaders.append("Authorization", `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}`);
        let formdata = new FormData();
        formdata.append("_idProveedor", params._idProveedor);
        formdata.append("tipoDocumentoTributario", params.tipoDocumentoTributario);
        formdata.append("monedaKey", params.monedaKey);
        formdata.append("numeroDocumentoTributario", params.numeroDocumentoTributario);
        formdata.append("importe", params.importe);
        formdata.append("iva", params.iva);
        formdata.append("tipoIvaKey", params.tipoIvaKey);
        formdata.append("importeIva", params.importeIva);
        formdata.append("importeTotal", params.importeTotal);
        formdata.append("observacion", params.observacion);
        formdata.append('datosTrabajo',params.datosTrabajo);
        formdata.append("detalle", params.detalle);
        formdata.append("factura",params.factura);
        formdata.append("CDEmpresa",params.CDEmpresa);

        //docuemntosCD
        //
        params.anexo01?.forEach((archivo:any, index:any) => {
            formdata.append(`factura${index}`, archivo);
        });
        //console.log("params.docuemntosCD", params.docuemntosCD)
        params.docuemntosCD?.forEach((archivo:any, index:any) => {
            //console.log("archivo", archivo);
            if(archivo.codigo!=="Anexos" && archivo.codigo!=="Factura"){
                if(archivo.codigo!=""){
                    formdata.append(`CD-${archivo.codigo}`, archivo.file);
                }
            }

        });

        const response =await fetch(`${this.urlBase}/catalogo/proveedor/insertFactura`,{
            method:'post',
            body: formdata,
            headers: {
                "Authorization": "Basic U2lzdGVtYUdJQTpTb3BvcnRlOTMxMg==",
                "request-decrypt-response": "yes",
            },
        });
        return response;
        
    }

    public async getListControlDocumental(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null> {
        return this._getListControlDocumental(params);
    }
    private async _getListControlDocumental(params: DtoRequestControlDocumental): Promise<ListaControlDocumental[] | null> {
        console.log("params", params)
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/selectListaControlDocumental`, newParams, 'bearer', "json", "json", {}, 0);
    }


    public async getCargarFileControlDocumento(params: any): Promise<any> {
        return this._getCargarFileControlDocumento(params);
    }
    private async _getCargarFileControlDocumento(params: any): Promise<any> {
        console.log("params", params)
        let { token }: { token: string; } = AdapterStorage.get('token');
        // let myHeaders = new Headers();
        // myHeaders.append("request-decrypt-response", "yes");
        // myHeaders.append("Authorization", `Basic ${Buffer.from(process.env.REACT_APP_AUTH_BASIC_USER + ':' + process.env.REACT_APP_AUTH_BASIC_PASS).toString('base64')}`);
        let formdata = new FormData();
        formdata.append("documento",params.documento);
        formdata.append("_id",params._id);
        formdata.append("CodigoDocumento",params.CodigoDocumento);
        formdata.append("NombreDocumento",params.NombreDocumento);
        
        const response =await fetch(`${this.urlBase}/catalogo/proveedor/CargaFileControlDocumental`,{
            method:'post',
            body: formdata,
            headers: {
                "Authorization": `Bearer ${token}`,
                "request-decrypt-response": "yes",
            },
        });

        console.log("response", response)
        return response;

    }

    public async getEleiminarileControlDocumento(params: any): Promise<any> {
        return this._getEleiminarileControlDocumento(params);
    }
    private async _getEleiminarileControlDocumento(params: any): Promise<any> {
        const newParams = JSON.stringify(params)
        return await this.service.bgCall<any>('POST', `${this.urlBase}/catalogo/proveedor/EliminarFileControlDocumental`, newParams, 'bearer', "json", "json", {}, 0);
    }
}

