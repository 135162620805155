//Dependency External
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';

//UseCase
import { UseCaseLogin } from '../Application/UseCaseLogin';

//Entities
import { DtoRequestLogin } from '../Domain/DtoRequestLogin';
import { DtoResponseLogin } from '../Domain/DtoResponseLogin';


import { changePreference, signIn, signInInvite } from '../../../shared/Infraestructure/SliceAuthentication';
import { useSelector } from 'react-redux';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const { language } = AdapterStorage.get('language');
    let { recordar } = AdapterStorage.get('recordar');

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const [showPassword, setShowPassword] = useState(false);

    const formLogin = useFormik({
        initialValues: { username: !!recordar ? recordar.username : '', password: !!recordar ? recordar.password : '', recordar: !!recordar, },
        validationSchema: Yup.object({
            username: Yup.string().required('Ingresar Usuario (RUT)').matches(/^[a-zA-Z0-9]{8,}$/, 'Ingresar Usuario (RUT)').min(8, `Ingresar Usuario (RUT)`),
            password: Yup.string().required('Ingresar Contraseña'),
            recordar: Yup.boolean().notRequired(),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formLogin.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formLogin.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formLogin.validateForm()); } catch (error) { AdapterGenerico.createMessage('Incompleto', (error as Error).message, 'warning', false); return null; }
        
            dispatch(addLoading({ textLoading: 'Cargando...' }));

            let params: DtoRequestLogin = {
                username: formLogin.values.username,
                password: formLogin.values.password,
                captcha:'',
            };

            let response: DtoResponseLogin | null = await (new UseCaseLogin(repository)).exec(params);
            if (response === null) { throw Error('Ocurrio un error al consultar el servicio'); }

            let user: EntityDataUsuario = {
                usuario: response.usuario,
                token: response.token
            };

            if (formLogin.values.recordar) { AdapterStorage.set('recordar', JSON.stringify({ username: formLogin.values.username, password: formLogin.values.password })); }
            else { AdapterStorage.remove('recordar'); }

            var { [`preference${user.usuario._id}`]: preference } = AdapterStorage.get(`preference${user.usuario._id}`);

            dispatch(removeLoading());
            dispatch(signIn({ token: response.token.accessToken, tokenRefresh: response.token.refreshToken, user, menu: 'response.permisos.menu', }));
            dispatch(changePreference(preference));
            //dispatch(changePermisoVariable({ arrIdPaises, arrIdGrupos, arrIdDelegaciones, arrIdOT }));
            formLogin.resetForm();
            //let response: DtoResponseLogin | null = await (new UseCaseLogin(repository)).exec(params);
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        };
    };

    const onClickRecuperarPassword = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`, { replace: true });
    };

    const onClickRegisterRDI = () => {
        try {
            dispatch(signInInvite());
            navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}`, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage('Alerta', (error as Error).message, 'warning', false);
        }
    }
     const onChangeLanguage = async (code: string) => {
        AdapterStorage.set('language', code);
        window.location.reload();
    };
    
    
    return {
        showPassword,
        formLogin,
        onChange,
        onSubmit,
        setShowPassword,
        onClickRecuperarPassword,
        onClickRegisterRDI,
        onChangeLanguage,
        language,
    };
}